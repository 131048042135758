var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "custom-footer"
  }, _vm._l(_vm.footerInfo, function (item) {
    return _c('li', {
      key: item._id
    }, [_c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.term))]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.desc) + " ")]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }