<template>
    <ul class="custom-footer">
        <li v-for="item in footerInfo" :key="item._id">
            <span>{{ item?.term }}</span> {{ item?.desc }}
        </li>
    </ul>
</template>

<script>
import api from "@/api";
export default {
    data: () => ({
        footerInfo: [],
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            let { setting: { footerInfo = [] } = {} } = (await api.v1.common.setting.get()) || {};
            this.footerInfo = footerInfo;
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-footer {
    padding: 0;
    font-size: 0.875rem;
    list-style-type: none;

    > li {
        display: inline-block;
        padding: 2px 6px;
    }

    > li > span {
        font-weight: bold;
    }
}
</style>